<template>
  <div class="panel-container">
    <div v-if="isPlayAudio && !audioOverride">
      <v-alert type="info">
        <span>Audio is playing, click the play button below to play the stream</span>
      </v-alert>
      <v-btn color="success" @click="audioOverride = true" title="Cinema Mode">
        <v-icon class="mr-2">{{ mdiPlayCircle }}</v-icon>
        Play
      </v-btn>
    </div>
    <VideoPlayer v-if="!isPlayAudio || audioOverride" :url="url">
      <div v-if="!isPlayAudio || audioOverride" class="video-button" >
        <v-btn
        v-if="!$vuetify.breakpoint.mobile"
        @click="$emit('togglePanel')"
        >
          <v-icon
            v-if="!panelVisible">
            {{ mdiArrowExpandLeft }}
          </v-icon>
          <v-icon v-else>{{ mdiArrowExpandRight }}</v-icon>
        </v-btn>
      </div>
    </VideoPlayer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mdiPlayCircle, mdiArrowExpandLeft, mdiArrowExpandRight } from '@mdi/js';
import VideoPlayer from './VideoPlayer.vue';

export default {
  name: 'VideoStream.vue',
  components: {
    VideoPlayer,
  },
  props: {
    panelVisible: {
      type: Boolean,
      default: true,
    },
    url: {
      type: String,
      default: 'https://player.castr.com/live_43bac4c0812511edb24427eebfba19c9',
    },
  },
  data() {
    return {
      mdiPlayCircle,
      mdiArrowExpandLeft,
      mdiArrowExpandRight,
      audioOverride: false,
    };
  },
  computed: {
    ...mapState('app', ['isPlayAudio']),
  },
};
</script>
