<template>
  <SplitLayout :rightPanelVisible="panelVisible">
    <template v-slot:left>
      <ProductCountdown :product="product" />
      <component
        :is="resolveLayout"
        :url="product.streamUrl" @togglePanel="togglePanel" :panel-visible="panelVisible"
      />
    </template>
    <template v-if="panelVisible" v-slot:right>
      <div class="panel-container">
        <div class="fill-height chat">
          <div class="chat-box">
            <Chat />
          </div>
        </div>
      </div>
    </template>
  </SplitLayout>
</template>

<script>
import {
  mdiEmoticon,
  mdiArrowRightCircle,
  mdiCloseCircle,
  mdiArrowExpandLeft,
  mdiArrowExpandRight,
  mdiPlayCircle,
} from '@mdi/js';
import { mapActions, mapGetters, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';
import SplitLayout from '../layouts/SplitLayout.vue';
import VideoStream from '../components/VideoStream.vue';
import PayPerViewSetup from '../components/PayPerViewSetup.vue';
import Chat from '../components/Chat.vue';
import EncryptionHelper from './EncryptionHelper';
import ProductCountdown from '../components/ProductCountdown.vue';

const encryptionHelper = new EncryptionHelper(process.env.VUE_APP_ECENTRIC_KEY);

export default {
  name: 'PayPerView',
  module: 'Stream',
  components: {
    Chat,
    PayPerViewSetup,
    ProductCountdown,
    SplitLayout,
    VideoStream,
  },
  mixins: [loading],
  data() {
    return {
      mdiArrowRightCircle,
      mdiEmoticon,
      mdiCloseCircle,
      mdiArrowExpandLeft,
      mdiArrowExpandRight,
      mdiPlayCircle,
      audioOverride: false,
      marker: true,
      showEmoji: false,
      panelVisible: true,
      user: '',
      text: '',
      connection: null,
      menuOpen: false,
      offset: true,
    };
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('products', ['product']),
    ...mapState('purchases', ['purchases']),
    resolveLayout() {
      if (this.isLoggedIn) {
        return 'VideoStream';
      }
      return 'PayPerViewSetup';
    },
    merchantGuid() {
      return process.env.VUE_APP_ECENTRIC_GUID;
    },
    merchantKey() {
      return process.env.VUE_APP_ECENTRIC_KEY;
    },
    paymentUrl() {
      return process.env.VUE_APP_ECENTRIC_URL;
    },
    baseUrl() {
      return process.env.VUE_APP_APIURL;
    },
    productId() {
      return this.$route.params.id;
    },
    checksum() {
      return encryptionHelper.getRequestChecksum(this.merchantGuid, 'Payment', this.product.price, 'ZAR', this.product.id);
    },
    screenSize() {
      if (this.$vuetify.breakpoint.mobile) {
        return 12;
      }
      return 8;
    },
  },
  async mounted() {
    if (this.isLoggedIn) {
      await this.refresh();
    }
  },
  methods: {
    ...mapActions('purchases', ['LOAD_purchaseByProductId']),
    ...mapActions('products', ['LOAD_product']),
    async refresh() {
      try {
        this.isLoading = true;
        await Promise.all([
          (async () => {
            await this.LOAD_purchaseByProductId(this.$route.params.id);
          })(),
          (async () => {
            await this.LOAD_product(this.$route.params.id);
          })(),
        ]);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    togglePanel() {
      this.panelVisible = !this.panelVisible;
    },
  },
};
</script>
<style>
  /* .stream-container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }
  .video-button {
    position: absolute;
    top: 50px;
    right: 5px;
    margin: 12px;
    opacity: 0.8;
  }
  .video {
    flex: 5;
    padding: 12px;
    height: 100%;
    position: relative;
  }
  .users {
    flex: 1;
    padding: 12px;
    height: 100%;
  }
  @media (max-width: 767px) {
    .stream-container {
      display: flex;
      flex-wrap: nowrap !important;
      flex-direction: column !important;
      height: 100%;
    }
    .video {
      order: 1;
      flex-grow: 0;
    }
    .chat {
      order: 2;
    }
    .users {
      display: none;
    }
  } */
</style>
