import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { components } from '@codehq/aurora-app-core/src';
import LocaleSwitcher from '@codehq/aurora-app-core/src/components/LocaleSwitcher.vue';
import TableBasicButtons from '@codehq/aurora-app-core/src/components/TableBasicButtons.vue';
import VueOwinAuth from '@codehq/vue-owin-auth/src/vueOwinAuth';
import DatetimePicker from 'vuetify-datetime-picker';
import VueMask from 'v-mask';
import VuetifyConfirm from 'vuetify-confirm';

import './open-telemetry';

import { o } from 'odata';

import App from './App.vue';
import auroraModule from './plugins/auroraModule';
import AutoSelect from './components/AutoSelect.vue';
import AdminCard from './components/AdminCard.vue';
import DatePicker from './components/DatePicker.vue';
import DatePickerSmall from './components/DatePickerSmall.vue';
import SubmitButton from './components/SubmitButton.vue';
import TimePicker from './components/TimePicker.vue';
import vuetify from './plugins/vuetify';
import formatting from './plugins/formatting';
import store from './store';
import { router, routes } from './router';
import { i18n, messages } from './i18n';
import authMixin from './mixins/auth-mixin';

Vue.config.productionTip = false;

// register global components
Vue.use(VueAxios, axios);
Vue.use(VueOwinAuth, {
  url: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
});
Vue.use(VuetifyConfirm, { vuetify });

// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'
Vue.use(DatetimePicker);

const { ImageUpload } = components;

Vue.use(VueMask);

// register app components
Vue.component(AutoSelect.name, AutoSelect);
Vue.component(DatePickerSmall.name, DatePickerSmall);
Vue.component(DatePicker.name, DatePicker);
Vue.component(SubmitButton.name, SubmitButton);
Vue.component(TimePicker.name, TimePicker);
Vue.component(AdminCard.name, AdminCard);

// register aurora components and plugin
Vue.component(LocaleSwitcher.name, LocaleSwitcher);
Vue.component(TableBasicButtons.name, TableBasicButtons);
Vue.component(ImageUpload.name, ImageUpload);

// setup store
store.$http = axios;

// setup app plugins
Vue.use(auroraModule, {
  baseUrl: process.env.VUE_APP_APIURL,
  store,
});
Vue.use(formatting);

// Setup API
axios.defaults.baseURL = process.env.VUE_APP_APIURL;

o(process.env.VUE_APP_APIURL);

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
  mixins: [authMixin],

  i18n,
  mounted() {
    this.$log.debug('Locales:');
    this.$log.debug(messages);
    this.$log.debug('Routes:');
    this.$log.debug(routes);
  },
}).$mount('#app');
