<template>
  <div>
    <v-alert type="info">
      <span>
        You need to be logged in to purchase a ticket
      </span>
    </v-alert>
    <v-row class="ml-5">
      <v-col cols="2" class="ml-5">
        <v-btn block color="success" :to="{ name: 'auth-Login' }">
          Login
        </v-btn>
      </v-col>
      <v-col cols="2">
        <v-btn block color="primary" :to="{ name: 'auth-Register' }">
          Register
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'PayPerViewSetup',
};
</script>
