<template>
  <v-alert v-if="isActive && countdown !== 'Started'" type="info">
    <p>Stream starts in: {{ countdown }}</p>
  </v-alert>
</template>

<script>
export default {
  name: 'ProductCountdown',
  props: {
    product: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      countdown: '',
      timer: null,
    };
  },
  computed: {
    isActive() {
      return this.dateDiff > 0;
    },
    dateDiff() {
      return this.getDifferenceInSeconds(this.product.start, new Date());
    },
    now() {
      return new Date();
    },
  },
  mounted() {
    this.updateCountdown();
    this.timer = setInterval(this.updateCountdown, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    updateCountdown() {
      console.log('updateCountdown');
      const start = new Date(this.product.start);
      const diff = start - new Date();

      if (diff <= 0) {
        this.countdown = 'Started';
        clearInterval(this.timer);
        return;
      }

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      this.countdown = `${hours}h ${minutes}m ${seconds}s`;
      this.$forceUpdate();
    },
  },
};
</script>
