import {
  mdiHome,
  mdiImageAlbum,
  mdiForum,
  mdiTshirtCrew,
  mdiEmailNewsletter,
  mdiLogin,
  mdiLogout,
  mdiNewspaper,
  mdiHardHat,
  mdiCalendarMonth,
  mdiMotionPlayOutline,
} from '@mdi/js';
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'hasAdminClaim']),
    menuItems() {
      const menuItems = [
        {
          name: 'menu.home', pathName: 'app-Home', icon: mdiHome, topMenu: false,
        },
        {
          name: 'menu.articles', pathName: 'app-Articles', icon: mdiNewspaper, topMenu: false,
        },
        {
          name: 'menu.event', pathName: 'app-FeaturedEvents', icon: mdiImageAlbum, topMenu: false,
        },
        {
          name: 'menu.chat', pathName: 'app-Stream', icon: mdiForum, topMenu: true,
        },
        {
          name: 'menu.crew', pathName: 'app-Crew', icon: mdiTshirtCrew, topMenu: true,
        },
        {
          name: 'menu.contactUs', pathName: 'app-ContactUs', icon: mdiEmailNewsletter, topMenu: true,
        },
        {
          name: 'menu.broadcastSchedule', pathName: 'app-BroadcastSchedule', icon: mdiCalendarMonth, topMenu: true,
        },
        {
          name: 'menu.eventsSchedule', pathName: 'app-EventsSchedule', icon: mdiCalendarMonth, topMenu: false,
        },
      ];
      if (this.isLoggedIn && this.hasAdminClaim) {
        menuItems.push({
          name: 'menu.admin', pathName: 'app-Admin', icon: mdiHardHat, topMenu: false,
        });
      }
      if (this.isLoggedIn) {
        menuItems.push({
          name: 'menu.playBack', icon: mdiMotionPlayOutline, topMenu: false, pathName: 'app-Playback',
        });
        menuItems.push({
          name: 'menu.Logout', icon: mdiLogout, topMenu: false, cmd: this.logout,
        });
      } else {
        menuItems.push({
          name: 'menu.Login/Register', pathName: 'auth-Login', icon: mdiLogin, topMenu: false,
        });
      }
      return menuItems;
    },
  },
  methods: {
    ...mapActions('auth', ['LOGOUT_auth']),
    logout() {
      this.LOGOUT_auth();
      this.$router.push('/');
    },
  },
};
