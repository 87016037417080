<template>
  <SplitLayout :rightPanelVisible="panelVisible">
    <template v-slot:left>
      <VideoStream @togglePanel="togglePanel" :panel-visible="panelVisible" />
    </template>
    <template v-if="panelVisible" v-slot:right>
      <div class="panel-container">
        <div class="fill-height chat">
          <div class="chat-box">
            <Chat />
          </div>
        </div>
      </div>
    </template>
  </SplitLayout>
</template>

<script>
import {
  mdiEmoticon,
  mdiArrowRightCircle,
  mdiCloseCircle,
  mdiArrowExpandLeft,
  mdiArrowExpandRight,
  mdiPlayCircle,
} from '@mdi/js';
import { mapActions, mapGetters, mapState } from 'vuex';
import SplitLayout from '../layouts/SplitLayout.vue';
import Chat from '../components/Chat.vue';
import UsersWidget from '../components/UsersWidget.vue';
import VideoStream from '../components/VideoStream.vue';

export default {
  name: 'Stream',
  module: 'Stream',
  components: {
    SplitLayout,
    VideoStream,
    Chat,
    // eslint-disable-next-line vue/no-unused-components
    UsersWidget,
  },
  computed: {
    ...mapState('app', ['isPlayAudio']),
    ...mapState('auth', ['userId', 'username', 'claims']),
    ...mapState('users', ['allUsers']),
    ...mapGetters('auth', ['hasClaim']),
    baseUrl() {
      return process.env.VUE_APP_APIURL;
    },
    screenSize() {
      if (this.$vuetify.breakpoint.mobile) {
        return 12;
      }
      return 8;
    },
  },
  data() {
    return {
      mdiArrowRightCircle,
      mdiEmoticon,
      mdiCloseCircle,
      mdiArrowExpandLeft,
      mdiArrowExpandRight,
      mdiPlayCircle,
      audioOverride: false,
      marker: true,
      showEmoji: false,
      panelVisible: true,
      user: '',
      text: '',
      connection: null,
      menuOpen: false,
      offset: true,
    };
  },
  async mounted() {
    await this.LOAD_user();
  },
  methods: {
    ...mapActions('auth', ['LOAD_user']),
    togglePanel() {
      this.panelVisible = !this.panelVisible;
    },
  },
};
</script>
