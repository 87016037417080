<template>
  <div class="tv-border">
    <CastrVideoPlayer :url="url" />
    <slot />
  </div>
</template>

<script>
import CastrVideoPlayer from './CastrVideoPlayer.vue';

export default {
  components: {
    CastrVideoPlayer,
  },
  props: {
    url: {
      type: String,
      default: 'https://player.castr.com/live_43bac4c0812511edb24427eebfba19c9',
    },
  },
};
</script>

<style scoped>
.tv-border {
  width: fit-content;
  padding: 20px;
  border: 10px solid black;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-color: black;
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
