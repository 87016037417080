<template>
  <router-link v-if="product" :to="{
    name: 'app-PayPerView',
    params: { id: product.id },
  }">
    <v-img
      :src="product.image"
      height="100%"
      width="100%" class="white--background" contain>
    </v-img>
  </router-link>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'ProductBanner',
  computed: {
    ...mapGetters('products', ['getViewerLink']),
    ...mapState('products', ['products']),
    product() {
      if (this.products.length === 0) {
        return undefined;
      }
      return this.products[0];
    },
  },
  methods: {
    ...mapActions('products', ['LOAD_activeProducts']),
  },
  created() {
    console.log('ProductBanner created');
    this.LOAD_activeProducts();
  },
};
</script>
