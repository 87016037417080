import dayjs from 'dayjs';

export default {
  /**
  * Install the plugin
  */
  install(Vue) {
    // formatting mixin
    Vue.mixin({
      methods: {
        formatDateTime(dt) {
          return dayjs(dt).format('YYYY-MM-DD HH:mm');
        },
        formatTime(dt) {
          return dayjs(dt).format('HH:mm');
        },
        formatIso(dt) {
          return dayjs(dt).toISOString();
        },
        formatCurrency(money, currencyCode) {
          const userLang = navigator.language || navigator.userLanguage;
          const currency = currencyCode ?? 'ZAR';
          const opts = { currency, style: 'currency' };
          return new Intl.NumberFormat(userLang, opts).format(money); // 'CA$ 100.00'
        },
        formatNumber(money) {
          const userLang = navigator.language || navigator.userLanguage;
          return new Intl.NumberFormat(userLang).format(money ?? 0);
        },
        formatDate(dt) {
          return dayjs(dt).format('YYYY-MM-DD');
        },
        formatFromNow(dt) {
          return dayjs(dt).fromNow();
        },
        getDifferenceInSeconds(dt1, dt2) {
          return dayjs(dt1).diff(dt2, 'second');
        },
      },
    });
  },
};
