<template>
  <AdminCard label="Manage paid for events">
    <v-data-table
      :headers="headers"
      :items="products"
      :items-per-page="5"
    >
    <template v-slot:top>
      <v-btn color="primary" @click="create" small>Create Product</v-btn>
      <ModalProduct
        :show.sync="showEditDialog"
        :product="product"
        @close="showEditDialog = false"
        @refresh="refresh"
        @submit="saveProduct"
      />
    </template>
      <template v-slot:item.image="{ item }">
        <v-img :src="item.image" fit height="64" width="64" />
      </template>
      <template v-slot:item.viewerLink="{ item }">
        <a :href="getViewerLink(item)">
          {{ getViewerLink(item) }}
        </a>
        <a :href="getViewerLink(item)" target="_blank">
          <v-icon>{{ mdiOpenInNew }}</v-icon>
        </a>
      </template>
      <template v-slot:item.price="{ item }">
        R {{ formatNumber(item.price) }}
      </template>
      <template v-slot:item.start="{ item }">
        {{ formatDateTime(item.start) }}
      </template>
      <template v-slot:item.end="{ item }">
        {{ formatDateTime(item.end) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex">
          <v-icon title="Edit" @click="edit(item)">{{ mdiPencil }}</v-icon>
          <v-icon title="Delete" @click="remove(item)">{{ mdiDelete }}</v-icon>
        </div>
      </template>
    </v-data-table>
  </AdminCard>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { mdiPencil, mdiDelete, mdiOpenInNew } from '@mdi/js';
import ModalProduct from './ModalProduct.vue';
import loading from '../mixins/loading';

export default {
  name: 'AdminUsers',
  components: {
    ModalProduct,
  },
  mixins: [loading],
  computed: {
    ...mapGetters('products', ['getViewerLink']),
    ...mapState('products', ['products']),
  },
  data() {
    return {
      headers: [
        { text: '', value: 'image', width: '64px' },
        { text: 'Name', value: 'name' },
        { text: 'Viewer Link', value: 'viewerLink' },
        { text: 'Description', value: 'description' },
        { text: 'Stream URL', value: 'streamUrl' },
        { text: 'Price', value: 'price' },
        { text: 'Start', value: 'start', width: '150px' },
        { text: 'End', value: 'end', width: '150px' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      product: null,
      showEditDialog: false,
      mdiDelete,
      mdiPencil,
      mdiOpenInNew,
    };
  },
  mounted() {
    this.refresh();
  },
  methods: {
    ...mapActions('products', ['CREATE_product', 'UPDATE_product', 'LOAD_products', 'DELETE_product']),
    create() {
      this.product = {
        name: '',
        description: '',
        streamUrl: '',
      };
      this.showEditDialog = true;
    },
    /**
      * Refresh the product list
      */
    async refresh() {
      this.isLoading = true;
      try {
        await this.LOAD_products();
      } catch (error) {
        throw new Error(error);
      } finally {
        this.isLoading = false;
      }
    },

    /**
      * Edit the product
      * @param {object} product - The user object to product
      */
    edit(product) {
      this.product = product; // Set the product to the provided product
      this.showEditDialog = true; // Show the edit dialog
    },

    /**
      * Remove a product
      * @param {object} product - The product object to remove
      */
    async remove(product) {
      this.isLoading = true;
      const res = await this.$confirm('Are you sure you want to delete this product?');

      if (res) {
        try {
          await this.DELETE_product(product);
          this.refresh();
        } catch (error) {
          throw new Error(error);
        } finally {
          this.isLoading = false;
        }
      }
    },

    /**
      * Handle product update
      * Called when the product is updated in the EditProduct component
      */
    async saveProduct(product) {
      this.showEditDialog = false; // Hide the edit dialog
      this.isLoading = true;
      if (product.id) {
        await this.UPDATE_product(product);
      } else {
        await this.CREATE_product(product);
      }
      this.refresh(); // Fetch the updated product data
      this.product = null; // Clear the product
      this.isLoading = false;
    },
  },
};
</script>
