<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-card-title>
        <span class="headline">Install P-Fore App</span>
      </v-card-title>
      <v-card-text>
        <p>Would you like to install this app on your device?</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="declineInstall">Later</v-btn>
        <v-btn color="green darken-1" text @click="installApp">Install</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useLocalStorage } from '../use';

const storage = useLocalStorage();

export default {
  name: 'InstallModal',
  data() {
    return {
      dialog: false,
      deferredPrompt: null,
    };
  },
  methods: {
    declineInstall() {
      storage.setItem('install', false);
      this.dialog = false;
    },
    installApp() {
      this.dialog = false;
      this.deferredPrompt.prompt();
    },
  },
  mounted() {
    if (storage.getItem('install') === false) return;
    window.addEventListener('beforeinstallprompt', (e) => {
      this.dialog = true;
      this.deferredPrompt = e;
    });
  },
};
</script>
