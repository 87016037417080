const crypto = require('crypto');

class EncryptionHelper {
  constructor(secretKey) {
    this.secretKey = secretKey;
  }

  /**
     * Validate checksum for incoming payment response
     * @param {string} transactionID - Transaction ID generated by Ecentric
     * @param {string} merchantReference - Reference passed by Merchant
     * @param {string} result - '0' for failure, '1' for success
     * @param {string} failureMessage - Response message
     * @param {number} amount - Initial amount passed
     * @param {string} checksum - Checksum generated by Ecentric
     * @returns {boolean} - True if valid, otherwise false
     */
  validateResponse(transactionID, merchantReference, result, failureMessage, amount, checksum) {
    const checksumString = `${this.secretKey}|${transactionID}|${merchantReference}|${result}|${failureMessage}|${amount}`;
    const hash = crypto.createHash('sha256').update(checksumString, 'utf8').digest('hex').toUpperCase();
    return hash === checksum.toUpperCase();
  }

  /**
     * Generate checksum for Ecentric Payment Transaction
     * @param {string} merchantID - Merchant ID provided by Ecentric
     * @param {string} transactionType - Payment or Authorize
     * @param {number} amount - Payment amount
     * @param {string} currency - Currency ISO code
     * @param {string} merchantReference - Unique reference for payment session
     * @param {string} userID - Unique user identifier for Ecentric Wallet
     * @returns {string} - Checksum value
     */
  getRequestChecksum(merchantID, transactionType, amount, currency, merchantReference, userID = '') {
    let checksumString = `${this.secretKey}|${merchantID}|${transactionType}|${amount}|${currency}|${merchantReference}`;
    if (userID && userID.trim() !== '') {
      checksumString += `|${userID}`;
    }
    return crypto.createHash('sha256').update(checksumString, 'utf8').digest('hex').toUpperCase();
  }

  /**
     * Generate checksum for Wallet Session
     * @param {string} merchantID - Merchant ID provided by Ecentric
     * @param {string} userID - Unique user identifier
     * @returns {string} - Checksum value
     */
  getCardManageChecksum(merchantID, userID) {
    const checksumString = `${this.secretKey}|${merchantID}|${userID}`;
    return crypto.createHash('sha256').update(checksumString, 'utf8').digest('hex').toUpperCase();
  }
}

module.exports = EncryptionHelper;
